.home-hero-main{
      padding: 0 0 15px 0;
}
.hero-left-main .platform-text {
      color: var(--white);
      font-size: 60px;
      line-height: 66px;
      font-family: 'Poppins-Regular';
      margin: 20.4px 0 82px 0;
}

.offering-cus-table .offering-table-body {
      padding: 15px 27px;
      border: 1px solid var(--pink);
      border-top: none;
      border-radius: 0 0 15px 15px;
}

.offering-cus-table .offering-table-head {
      background: var(--gradient);
      border-radius: 15px 15px 0 0;
      padding: 11px 27px 11px 100px;
      display: flex;
      /* justify-content: space-between; */
}

.offering-table-head .offering-th {
      color: var(--white);
      font-size: 12px;
      line-height: 27px;
      font-family: 'Poppins-Regular';
      min-width: 29px;
}

.offering-tr .offering-tr-main {
      padding: 8px 11px 8px 42px;
}

.offering-tr-main .lock-main {
      width: 30px;
      height: 30px;
}

.lock-ic-main .lock-ic {
      width: 10.41px;
      height: 13.88px;
}

.offering-tr .offering-th-text {
      font-size: 12px;
      line-height: 27px;
      font-family: "Poppins-Medium";
      color: var(--white);
}

.offering-list .offering-td-box {
      width: 84px;
      height: 30px;
      margin-right: 13px;
}

.offering-list .offering-td-box:last-child {
      margin-right: 0;
}

.offering-table-head .th-offe-type {
      width: 129px;
      text-align: center;
}

.offering-table-head .th-quantity {
      width: 84px;
      text-align: center;
      margin-right: 10px;
}

.offering-table-head .th-quantity:last-child {
      margin-right: 0;
}

.offering-table-head .offering-pre-seed {
      width: 126px;
      text-align: center;
}

.offering-table-body .offering-tr {
      margin-bottom: 17px;
}

.offering-table-body .offering-tr:last-child {
      margin-bottom: 0;
}

.seed-wrapper {
      display: flex;
      justify-content: flex-end;
      padding-top: 27px;
}

.seed-wrapper .seed-main {
      width: 392px;
      height: 100%;
      background: #191F34;
      border-radius: 15px;
      opacity: 1;
      backdrop-filter: blur(50px);
      -webkit-backdrop-filter: blur(50px);
      position: relative;
      padding: 23px 21px 28px 18px;
      text-align: center;
}

.seed-main .seed-head-sec {
      margin-bottom: 24px;
}

.seed-head-sec .seed-title-sec {
      height: 47px;
      position: relative;
}

.seed-head-sec .seed-title-sec::before {
      content: '';
      height: 47px;
      width: 7px;
      background: transparent linear-gradient(176deg, #FF387A 0%, #4845FF 100%);
      position: absolute;
      left: 0;
      top: 0;
      border-radius: 10px;
}

.seed-title-sec .seed-title-text {
      font-size: 20px;
      line-height: 27px;
      color: var(--white);
      font-family: "Poppins-Regular";
      margin-left: 22px;
}

/* .seed-wrapper .seed-main::before {
      content: "";
      width: 100%;
      height: 593px;
      background: transparent linear-gradient(86deg, rgb(255 56 122 / 26%) 0%, rgb(72 69 255 / 26%) 100%) 0% 0% no-repeat padding-box;
      border-radius: 15px;
      opacity: 0.26;
      position: absolute;
      left: -13%;
      top: -1%;
      transform: rotate(349deg);
      z-index: 2;
} */
.seed-main .seed-main-before {
      content: "";
      width: 100%;
      height: 593px;
      background: transparent linear-gradient(86deg, rgb(255 56 122 / 26%) 0%, rgb(72 69 255 / 26%) 100%);
      border-radius: 15px;
      opacity: 0.26;
      position: absolute;
      left: -13%;
      top: -1%;
      transform: rotate(349deg);
      z-index: 2;
}
.seed-wrapper .seed-main::after {
      content: "";
      width: 100%;
      height: 100%;
      background: rgb(25 31 52 / 33%);
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      backdrop-filter: blur(63px);
      z-index: 2;
      border-radius: 15px;
  }
.open-sec .green-dot {
      background-color: var(--green);
      width: 10px;
      height: 10px;
      border-radius: 50%;
      margin-right: 6px;
}

.open-sec .open-text {
      font-family: var(--poppins-regular);
      color: var(--white);
      font-size: 16px;
      line-height: 27px;
}

.seed-progress-wrapper .seed-progress-title {
      margin-bottom: 10px;
}

.seed-progressbar-main {
      height: 7px;
      width: 100%;
      background: #24293B;
      border-radius: 10px;
      position: relative;
      margin-bottom: 10px;
}

.seed-progressbar-main .seed-progressbar-inner {
      height: 100%;
      width: 70%;
      background: var(--gradient);
      border-radius: 10px;
      position: absolute;
      left: 0;
      top: 0;
}

.seed-price-wrapper {
      padding: 23px 0;
      flex-direction: column;
      border-bottom: 1px solid #24293B;
}

.seed-price-wrapper .seed-price-value {
      font-family: var(--poppins-medium);
      color: var(--white);
      font-size: 28px;
      line-height: 27px;
      margin: 5px 0 0 0;
}

.seed-usdt-sec {
      padding: 25.5px 0 30px 0;
}

.seed-usdt-sec .seed-usdt-text {
      font-family: var(--poppins-medium);
      color: var(--white);
      font-size: 18px;
      line-height: 27px;
      margin: 0 0 21px 0;
      position: relative;
}

.seed-usdt-sec .seed-usdt-text::before,
.seed-usdt-sec .seed-usdt-text::after {
      content: "";
      width: 27%;
      height: 1px;
      background: var(--gradient);
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
}

.seed-usdt-sec .seed-usdt-text::before {
      right: 0;
      left: initial;
}

.bsc-sec {
      background: var(--gray);
      height: 50px;
      border: 1px solid var(--br-gray);
      border-radius: var(--border-radius-15);
      margin-bottom: 24px;
}

.bsc-sec .bsc-ic {
      width: 22.53px;
      height: 22.53px;
      object-fit: contain;
      margin-right: 8px;
}

.bsc-sec .seed-bsc-text {
      font-family: var(--poppins-medium);
}

.swap-sec-left {
      display: flex;
      align-items: center;
}

.swap-sec-left .round-bg {
      width: 42px;
      height: 42px;
      background: var(--gradient);
      border-radius: 50%;
      margin-left: 11px;
}

.swap-sec .dollar-text {
      font-family: var(--poppins-medium);
      color: var(--white);
      font-size: 20px;
      line-height: 27px;
}

.round-bg .bsc-ic {
      margin-right: 0;
}

.round-bg .plutope-ic {
      width: 15px;
      height: 30px;
      object-fit: contain;
}

.swap-sec .bsc-sec {
      padding: 4px 4px 4px 19px;
      width: 291px;
      margin: 0 13px 0 0;
      display: flex;
      align-items: center;
}

.swap-sec .gradient-text {
      font-size: 25px;
      line-height: 40px;
}

.swap-sec {
      margin: 0 0 30px 0;
      display: flex;
      justify-content: center;
      align-items: center;
}
.position-relative{
      position: relative;
      z-index: 3;
}
.home-input-main .form-control input{ 
      border: 0px;
      text-align: center;
      font-size: 20px;
      line-height: 25px;
      color: #fff;
}
.home-input-main .form-control input::placeholder{
      font-size: 20px;
      line-height: 25px;
      color: #fff;
}
.home-input-main .form-control fieldset{
      border:0px;
}
.offering-tr-main.active .offering-th-text{
color: var(--green);
}
.offering-tr-main .unlock-ic,
.offering-tr-main.active .lock-ic{
      display: none;
}
.offering-tr-main.active .unlock-ic,
.offering-tr-main .lock-ic{
      display: flex;
}
.converter-wrape fieldset {display: none}
.converter-wrape .MuiSelect-select {
      padding-top: 5px;
      padding-bottom: 5px;
      font-size: 0;
      display: flex;
      align-items: center;
}
.converter-wrape svg {
      fill: #ffffff;
}

.MuiMenu-list img {
      margin-right: 5px;
}

.MuiMenu-paper {
      background: var(--gray) !important;
    border: 1px solid var(--br-gray);
    border-radius: var(--border-radius-15);
    margin-bottom: 24px;
}
.MuiMenu-paper .MuiMenuItem-root {
      color: #ffffff;
}


.loader {
      animation: rotate 1s infinite;
      height: 50px;
      width: 50px;
}

.loader:before,
.loader:after {
      border-radius: 50%;
      content: "";
      display: block;
      height: 20px;
      width: 20px;
}

.loader:before {
      animation: ball1 1s infinite;
      background-color: #fff;
      box-shadow: 30px 0 0 #ff3d00;
      margin-bottom: 10px;
}

.loader:after {
      animation: ball2 1s infinite;
      background-color: #ff387a;
      box-shadow: 30px 0 0 #fff;
}

@keyframes rotate {
      0% {
            transform: rotate(0deg) scale(0.8)
      }

      50% {
            transform: rotate(360deg) scale(1.2)
      }

      100% {
            transform: rotate(720deg) scale(0.8)
      }
}

@keyframes ball1 {
      0% {
            box-shadow: 30px 0 0 #ff387a;
      }

      50% {
            box-shadow: 0 0 0 #ff387a;
            margin-bottom: 0;
            transform: translate(15px, 15px);
      }

      100% {
            box-shadow: 30px 0 0 #ff387a;
            margin-bottom: 10px;
      }
}

@keyframes ball2 {
      0% {
            box-shadow: 30px 0 0 #fff;
      }

      50% {
            box-shadow: 0 0 0 #fff;
            margin-top: -20px;
            transform: translate(15px, 15px);
      }

      100% {
            box-shadow: 30px 0 0 #fff;
            margin-top: 0;
      }
}