/* tablets */
@media screen and (min-width: 768px) and (max-width: 991px) {
    .header-social-link .header-social-icon {
        height: 20px;
        width: 20px;
        margin-right: 20px;
    }
    .header-nav-main .header-nav-li {
        margin-right: 35px;
    }
    .header-logo-box .header-logo {
        height: 45px;
    }
}
/* phone */
@media (max-width: 767px) {
    .header-cust-col .burger-menu-icon{
        display: block;
  }
  .header-nav-main{
    background: #0d1740;
    height: 100vh;
    left: -250px;
    overflow: auto;
    position: fixed;
    top: 0;
    width: 250px;
    z-index: 9999;
    transition: 0.5s all;
  }
  .header-nav-main.mobilemenu-active {
    left: 0;
    padding: 10px;
}
  .header-social-link .header-social-icon {
    height: 15px;
    width: 15px;
    margin-right: 10px;
}
.header-logo-box .header-logo {
    height: 40px;
}
.header-nav-main .header-nav-ul{
    display: block;
    /* padding: 50px 10px 10px; */
}
.header-nav-main .header-nav-link{
    padding: 10px 12px 10px;
    width: 100%;
    margin-bottom: 10px;
    color: #FFFFFF;
}
.header-nav-main .header-nav-li {
    margin-right: 0;
    max-width: 100%;
}
.header-nav-main .header-nav-link.active {
    color: #FFFFFF;
    background-color: #1f2851;
    border-radius: 4px;
}
.overlay-section {
    position: fixed;
    right: 0;
    max-width: 100%;
    background: rgb(20 19 19 / 50%);
    top: 0;
    bottom: 0;
    width: 100%;
    transition: all 0.3s;
    z-index: 99;
}
.mobile-show {
    display: none;
}
.res-header-nav-li{
    display: flex !important;
}
.seed-main .seed-main-before{
    display: none;
}
  /* .header-nav-main .header-nav-ul {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    background: black;
    width: 250px;
    flex-direction: column;
    justify-content: start;
    align-items: start;
} */
.header-nav-main .close-ic{
    width: 25px;
    position: absolute;
    right: 10px;
    top: 10px;
    display: block;
}
}