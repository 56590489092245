* {
      box-sizing: border-box;
      padding: 0px;
      margin: 0px;
}

body {
      background-color: var(--darkBlue);
      color: var(--white);
}

:root {
      --black: #000000;
      --white: #ffffff;
      --pink: #CE3B9D;
      --blue: #4845FF;
      --green: #18BF2E;
      --darkBlue: #040B28;
      --gray: #24293B;
      --br-gray: #47495A;
      --gradient: linear-gradient(99deg, #FF387A 0%, #4845FF 100%);
      --border-radius-5: 5px;
      --border-radius-15: 15px;
      --poppins-bold: "Poppins-Bold";
      --poppins-regular: "Poppins-Regular";

      --poppins-medium: "Poppins-Medium";
}

.cus-center {
      display: flex;
      align-items: center;
      justify-content: center;
}

.d-flex {
      display: flex;
}

.space-between {
      display: flex;
      align-items: center;
      justify-content: space-between;
}

@font-face {
      font-family: "Poppins-Italic";
      src: url(../fonts/Poppins/Poppins-Italic.ttf);
}


@font-face {
      font-family: 'Poppins-Regular';
      src: url(../fonts/Poppins/Poppins-Regular.ttf);
}

@font-face {
      font-family: 'Poppins-Bold';
      src: url(../fonts/Poppins/poppins-bold.ttf);
}

@font-face {
      font-family: 'Poppins-Medium';
      src: url(../fonts/Poppins/Poppins-Medium.ttf);
}

@font-face {
      font-family: 'Poppins-SemiBold';
      src: url(../fonts/Poppins/Poppins-SemiBold.ttf);
}

.grid-main .grid-item {
      background-color: transparent;
      box-shadow: none;
      border-radius: 0px;
      padding: 0px;
}

.container {
      padding: 0px 15px;
      width: 100%;
      margin: auto;
      max-width: 1136px;
}

.text-decoration-none {
      text-decoration: none !important;
}

/* end input css */


.text-decoration-none {
      text-decoration: none !important;
}

.flex-all {
      display: flex;
      justify-content: space-between;
}


/* start input field design css */

.form-group .form-control input {
      border: 1px solid #D1D5DB;
      border-radius: 4px;
      width: 100%;
      padding: 10px 15px;
      background-color: transparent;
      color: #6B7280;
      font-size: 12px;
      line-height: 20px;
      font-family: 'Poppins-Regular';
}

.form-group .form-control input::placeholder {
      color: #6B7280;
      font-size: 12px;
      line-height: 20px;
      font-family: 'Poppins-Regular';
      opacity: 1;
}

.form-group .form-control .Mui-focused .MuiOutlinedInput-notchedOutline {
      border-color: transparent;
      border-width: 0px;
}

.form-control fieldset {
      border: 1px solid #D1D5DB;
      border-radius: 2px;
}

.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
      border-color: #D1D5DB !important;
}

.form-control-textarea:focus-visible {
      outline: none !important;
}

/* end input field design css */

.primary-btn-main .primary-btn,
.primary-btn-main .primary-btn:hover {
      background: linear-gradient(109deg, #FF387A 0%, #4845FF 100%) 0% 0%;
      border-radius: 15px;
      width: 100%;
      color: var(--white);
      font-size: 16px;
      line-height: 27px;
      font-family: var(--poppins-medium);
      padding: 13.5px 15px;
      text-transform: capitalize;
}
.border-btn-main .border-btn,
.border-btn-main .border-btn:hover {
      background: transparent;
      border-radius: 15px;
      width: 100%;
      color: var(--white);
      font-size: 16px;
      line-height: 27px;
      font-family: var(--poppins-medium);
      padding: 13.5px 15px;
      text-transform: capitalize;
      border: 1px solid #FFFFFF;
      border-radius: 15px;
}

.common-para {
      font-size: 16px;
      line-height: 27px;
      font-family: var(--poppins-regular);
      color: var(--white);
} 
.display-row {
      margin-left: -8px;
      margin-right: -8px;
}

.display-row>* {
      padding: 0px 8px;
}

.gradient-text {
      font-size: 28px;
      line-height: 45px;
      font-family: 'Poppins-Medium';
      background: -webkit-linear-gradient(297deg, #FF387A, #4845FF);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      margin: 0 0 24.4px 0;
}

.hero-left-main {
      padding-top: 79px;
}

.gradient-box {
      background: var(--gradient);
      padding: 1px;
      border-radius: var(--border-radius-5);
}

.gradient-inner-box {
      width: 100%;
      height: 100%;
      background: var(--darkBlue);
      border-radius: var(--border-radius-5);
}
.w-100{
      width: 100%;
}
.dd-icon {
      width: 20px;
      height:20px;
}