@media screen and (min-width: 1600px) and (max-width: 1920px) {
  
}

@media screen and (min-width: 1366px) and (max-width: 1599px) {}

@media screen and (min-width: 1280px) and (max-width: 1365px) {}

/* ipad pro */
@media screen and (min-width: 992px) and (max-width: 1199px) {
}

/* phone and tablets */
@media screen and (max-width: 991px) {
      
}

/* tablets */
@media screen and (min-width: 768px) and (max-width: 991px) {
      .body-overflow {
            overflow: hidden;
      }
}

/* phone */
@media (max-width: 767px) {
   .usdt-table-sec,
   .res-cus-table{
      overflow: auto;
   }
   .usdt-table-sec .offering-cus-table,
   .res-cus-table .offering-cus-table{
      min-width: 546px;
   }
   .hero-left-main {
      padding-top: 0px;
  }
  /* .hero-left-main .platform-text {
      font-size: 45px;
      line-height: 63px;
      margin: 20.4px 0 50px 0;
  } */
  .seed-wrapper .seed-main {
      width: 100%;
  }
  .modal-delete .delete-modal-inner-main {
      padding: 15px;
      max-width: 95%;
  }
  .cus-nodal-ul .cus-modal-li {
      padding: 20px 5px;
  }
  .modal-btn-sec .modal-btn, .modal-btn-sec .modal-btn:hover {
      width: 150px !important;
      padding: 10px 15px;
  }
}