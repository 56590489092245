@media (max-width: 1400px) {
}

@media screen and (min-width: 1200px) and (max-width: 1299px) {
 
}

/* ipad pro */
@media screen and (min-width: 992px) and (max-width: 1199px) {

}

/* phone and tablets */
@media screen and (max-width: 991px) {
     
}

/* tablets */
@media screen and (min-width: 768px) and (max-width: 991px) {
      .container {
            padding: 0px 30px;
      }

      
      
}

/* phone */
@media (max-width: 767px) {
      .container {
            padding: 0px 15px;
      }

      /* start mobile menu responsive */

      
}
@media screen and (min-width: 280px) and (max-width:374px){
      
}
