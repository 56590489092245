/* phone and tablets */
@media screen and (max-width: 991px) {
    
}

/* tablets */
@media screen and (min-width: 768px) and (max-width: 991px) {
.res-grid-column{
    order: 2;
}
.seed-wrapper{
    justify-content: center;
}
}

/* phone */
@media (max-width: 767px) {
    .gradient-text {
        font-size: 22px;
        line-height: 35px;
    }
    .hero-left-main .platform-text {
        font-size: 35px;
        line-height: 50px;
        margin: 20.4px 0 30px 0;
    }
}