/* start header css */

.header-main {
      background-color: #040B28;
      padding: 30px 0px;
}

.header-row {
      display: flex;
      align-items: center;
      justify-content: space-between;
}

.header-logo-box .header-logo {
      height: 56px;
      /* max-width: 158px; */
      object-fit: contain;
      width: 100%;
}

.header-nav-main .header-nav-ul {
      display: flex;
      justify-content: center;
      align-items: center;
      list-style-type: none;
      padding: 0px;
      margin: 0px;
}

.header-nav-main .header-nav-li {
      padding: 0px;
      margin-right: 68px;
      max-width: max-content;
}

.header-nav-main .header-nav-li:last-child {
      margin-right: 0px;
}

.header-nav-main .header-nav-link {
      color: #FFFFFF;
      font-size: 16px;
      line-height: 25px;
      font-family: 'Poppins-Regular';
      text-decoration: none;
      transition: all .5s ease;
      outline: none;
}
.header-nav-main .header-nav-link:hover{
     color: var(--pink); 
}
.header-right-main {
      display: flex;
      align-items: center;
      position:relative;
}
.header-social-link{
      display: flex;
      transition: all .5s;
      
}
.header-social-link .header-social-icon{
      height: 20px;
      width: 20px;
      object-fit: contain;
      margin-right: 35px;
}
.header-right-main .header-social-link:last-child .header-social-icon{
      margin-right: 0;
}

.header-cust-col{
      display: flex;
}
.header-cust-col .burger-menu-icon{
      display: none;
      width: 35px;
      margin: 0 0 0 15px;
}
.res-header-nav-li{
      display: none !important;
}
.header-nav-main .close-ic{
      display: none;
}    
.header-social-link:hover .header-social-icon path{
      fill: var(--pink);
}
.cus-filter{
      filter: invert(1);
}
.user-dropdown{
      background: rgb(255 255 255 / 20%);
    width: 110px;
    padding: 10px;
    position: absolute;
    border-radius: 10px;
    top: 30px;
    right: -14px;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 999;
}
.user-li .user-drop-link{
  text-decoration: none;
  font-size: 12px;
    line-height: 26px;
    font-family: var(--poppins-bold);
    color: var(--white);
}
.user-close{
      display: none;
}
/* .user-li .user-drop-link .user-li-text{

} */
/* end header css */

