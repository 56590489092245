.back-btn-sec .back-ic {
    margin-right: 10.8px;
    width: 11.22px;
    height: 8.97px;
}

.back-btn-sec .back-btn {
    color: var(--white);
    font-size: 12px;
    line-height: 18px;
    font-family: var(--poppins-regular);
    text-decoration: none;
    cursor: pointer;
}

.main-title .title-text {
    color: var(--white);
    font-size: 22px;
    line-height: 33px;
    font-family: var(--poppins-regular);
}

.main-title {
    margin: 32px 0 45px 0;
}

.plt-card {
    background: #24293B;
    border-radius: 10px;
    padding: 21.5px 23.3px 17px 18px;
}

.plt-card-right .round-bg {
    width: 53px;
    height: 53px;
    background: var(--gradient);
    border-radius: 50%;
}

.plt-card-left .card-value {
    color: var(--white);
    font-size: 24px;
    line-height: 27px;
    font-family: var(--poppins-regular);
    margin-bottom: 8px;
}

.plt-card-left .card-title {
    color: var(--white);
    font-size: 14px;
    line-height: 27px;
    font-family: var(--poppins-regular);
}

.plt-card-right .claim-btn,
.plt-card-right .claim-btn:hover {
    color: var(--white);
    font-size: 12px;
    line-height: 27px;
    font-family: var(--poppins-medium);
    padding: 3px 22px;
    background: var(--darkBlue);
    border-radius: var(--border-radius-15);
    margin: 0 14px 0 0;
}

.plt-card-right {
    display: flex;
    align-items: center;
}
.usdt-table-sec .offering-cus-table .offering-table-head{
    padding: 11px 27px 11px 27px;
}
.usdt-table-sec .cus-table-th{
    width: 24.6%;
text-align: center;
}
.usdt-table-sec .offering-tr .offering-tr-main {
    padding: 10.1px 14px 10.1px 14px;
}
.view-btn-sec .primary-btn-main .view-btn,
.view-btn-sec .primary-btn-main .view-btn:hover{
    padding: 3px 25px;
    font-size: 12px;
    line-height: 17px;
}
.view-btn-sec{
    display: flex;
    align-items: center;
}
.w-25{
    width: 25% !important;
    display: flex;
    justify-content: center;
}
.usdt-table-sec .offering-list .offering-td-box {
    width: 64.71%;
    /* width: 163px; */
    height: 30px;
    margin-right: 13px;
}
.usdt-table-sec .offering-tr-main .gradient-box{
background: #FF387A;
}
.plt-card-sec{
    margin-bottom: 45px;
}
/* start delete user modal css */

.modal-delete .delete-modal-inner-main {
    padding: 23px 25px;
    max-width: 392px;
    width: 100%;
    background: rgb(25 31 52 / 33%);
    border-radius: 15px;
    backdrop-filter: blur(50px);
    -webkit-backdrop-filter: blur(50px);
}
.cus-nodal-ul .cus-modal-li{
    padding: 25px 5px;
    border-bottom: 1px solid #444857;
}
.cus-nodal-ul .cus-modal-li:last-child{
    border-bottom: none;
}
.cus-modal-li .li-list-title{
    font-size: 15px;
    line-height: 29px;
    color: var(--white);
    font-family: var(--poppins-regular);
}
.cus-modal-li .li-list-value{
    font-size: 17px;
    line-height: 29px;
    color: var(--white);
    font-family: var(--poppins-medium);
}
.modal-btn-sec .modal-btn,
.modal-btn-sec .modal-btn:hover{
    width: 160px !important;
}
.modal-btn-sec{
    justify-content: end !important;
} 
.modal-btn-sec .primary-btn{
    margin-left: 15px;
} 
/* end delete user modal css */